<template>
  <v-container
    style="padding: 0; margin: auto; border-radius: 5px; background: white"
  >
    <v-card
      tile
      flat
      style="
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 60px;
        background: white;
        z-index: 5;
      "
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        "
      >
        <v-toolbar-title class="overline">
          <p :style="`font-size:${wWidth > 780 ? '20px' : '3vw'}; margin: 0;`">
            Formulir Registrasi Karyawan
          </p>
        </v-toolbar-title>
      </div>
      <div>
        <v-btn
          type="button"
          rounded
          outlined
          elevation="0"
          color="red"
          class="white--text font-weight-bold ma-2"
          style="font-size: 12px"
          @click="close"
        >
          <v-icon small>mdi-chevron-left</v-icon>
          Tutup
        </v-btn>
      </div>
    </v-card>
    <div>
      <v-stepper class="elevation-0" v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1">
          Biodata Karyawan
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-form
            ref="stepOneForm"
            @submit.prevent="nextStep(1)"
            lazy-validation
            :disabled="loading"
          >
            <v-card elevation="0">
              <v-row style="padding: 15px" no-gutters>
                <!-- <v-col cols="12" md="9" style="padding: 0"></v-col> -->
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="4" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Nama *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.name"
                        :rules="rules.nameRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="4" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Email *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.email"
                        :rules="rules.emailRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. Telepon *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.mobile_phone_no"
                        :rules="rules.phoneRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Agama *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.religion"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        v-model="form.religion"
                        :rules="rules.religionRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Tempat *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.birth_place"
                        :rules="rules.birthPlaceRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Tanggal lahir *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        type="date"
                        name="date"
                        step="1"
                        v-model="form.birth_date"
                        :rules="rules.birthDateRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0; margin-bottom: 15px">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Jenis kelamin *
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      style="
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0;
                      "
                    >
                      <v-radio-group
                        v-model="form.gender"
                        :rules="rules.genderRules"
                        style="
                          width: 100%;
                          height: 30px;
                          position: relative;
                          top: -10px;
                        "
                      >
                        <div
                          class="d-flex justify-content-between align-content-between"
                        >
                          <div
                            style="
                              padding: 0;
                              justify-content: flex-start;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <v-radio
                              :value="1"
                              style="margin: 0; height: 25px; padding: 0"
                            ></v-radio>
                            <p style="margin: 0; font-size: 12px">Laki-laki</p>
                          </div>
                          <div
                            style="
                              padding: 0;
                              justify-content: flex-start;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <v-radio
                              :value="2"
                              style="margin: 0; height: 25px; padding: 0"
                            ></v-radio>
                            <p style="margin: 0; font-size: 12px">Perempuan</p>
                          </div>
                        </div>
                      </v-radio-group>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Pendidikan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        v-model="form.education_level"
                        :items="dropdown.education"
                        outlined
                        dense
                        style="margin: 0"
                        :rules="rules.educationRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="4" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Jurusan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.education_major"
                        :rules="rules.majorRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="4" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. KTP *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.no_identitas"
                        :rules="rules.ktpRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        NPWP
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.npwp_no"
                        :rules="rules.npwpRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Status PTKP
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.ptkp_status"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        clearable
                        v-model="form.ptkp_status_id"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Alamat Sesuai KTP *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        dense
                        auto-grow
                        rows="1"
                        outlined
                        v-model="form.address"
                        :rules="rules.addressRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Alamat Domisili *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        dense
                        auto-grow
                        rows="1"
                        outlined
                        v-model="form.domicile_address"
                        :rules="rules.domicileRules"
                      />
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Status Pernikahan *
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      style="
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0;
                      "
                    >
                      <v-radio-group
                        v-model="form.mariage_status"
                        :rules="rules.mariageRules"
                        style="
                          width: 100%;
                          height: 30px;
                          position: relative;
                          top: -10px;
                        "
                      >
                        <div
                          class="d-flex justify-content-between align-content-between"
                        >
                          <div
                            style="
                              padding: 0;
                              justify-content: flex-start;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <v-radio
                              :value="1"
                              style="margin: 0; height: 25px; padding: 0"
                            ></v-radio>
                            <p style="margin: 0; font-size: 12px">
                              Belum Menikah
                            </p>
                          </div>
                          <div
                            style="
                              padding: 0;
                              justify-content: flex-start;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <v-radio
                              :value="2"
                              style="margin: 0; height: 25px; padding: 0"
                            ></v-radio>
                            <p style="margin: 0; font-size: 12px">Menikah</p>
                          </div>
                          <div
                            style="
                              padding: 0;
                              justify-content: flex-start;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <v-radio
                              :value="3"
                              style="margin: 0; height: 25px; padding: 0"
                            ></v-radio>
                            <p style="margin: 0; font-size: 12px">Cerai</p>
                          </div>
                        </div>
                      </v-radio-group>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="3" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Golongan darah
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        v-model="form.blood_type"
                        :items="dropdown.blood_type"
                        outlined
                        dense
                        style="margin: 0"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Foto Profil
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-file-input
                        ref="photoProfile"
                        placeholder="pilih foto"
                        dense
                        outlined
                        append-icon="mdi-image"
                        prepend-icon=""
                        @change="uploadFoto"
                      ></v-file-input>
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <div class="d-flex justify-content-between align-center">
              <v-btn
                rounded
                outlined
                elevation="0"
                color="primary"
                type="submit"
                class="white--text font-weight-bold me-5"
                style="font-size: 12px"
                :loading="loading"
              >
                Berikutnya
              </v-btn>
              <v-btn
                type="button"
                rounded
                outlined
                elevation="0"
                color="red"
                @click="clearForm(1)"
                class="white--text font-weight-bold"
                style="font-size: 12px"
                :loading="loading"
              >
                Hapus
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">
          Informasi Kontrak
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-form
            ref="stepTwoForm"
            @submit.prevent="nextStep(2)"
            lazy-validation
            :disabled="loading"
          >
            <v-card elevation="0">
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" md="4" style="padding: 0; margin-bottom: 15px">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Kategori Registrasi *
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      style="
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0;
                      "
                    >
                      <v-radio-group
                        v-model="form.registration_type"
                        :rules="rules.registrationTypeRules"
                        style="
                          width: 100%;
                          height: 30px;
                          position: relative;
                          top: -10px;
                        "
                      >
                        <div
                          class="d-flex justify-content-between align-content-between"
                        >
                          <div
                            style="
                              padding: 0;
                              justify-content: flex-start;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <v-radio
                              :value="1"
                              style="margin: 0; height: 25px; padding: 0"
                            ></v-radio>
                            <p style="margin: 0; font-size: 12px">
                              Karyawan Baru
                            </p>
                          </div>
                          <div
                            style="
                              padding: 0;
                              justify-content: flex-start;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <v-radio
                              :value="2"
                              style="margin: 0; height: 25px; padding: 0"
                            ></v-radio>
                            <p style="margin: 0; font-size: 12px">Mutasi</p>
                          </div>
                        </div>
                      </v-radio-group>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Perusahaan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="getDropdownPlant || []"
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                        v-model="form.company"
                        :rules="rules.companyRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Department/ Section *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.department"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        clearable
                        v-model="form.department_id"
                        :rules="rules.departmentRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Jabatan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.position"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        v-model="form.level_id"
                        :rules="rules.positionRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Nomor Induk Karyawan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.nik"
                        :rules="rules.nikRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="4" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. Kartu
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field dense outlined v-model="form.card_no" />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Tgl. Masuk *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        type="date"
                        name="date"
                        step="1"
                        v-model="form.join_date"
                        :rules="rules.joinDateRules"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Status Karyawan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.employee_status"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        v-model="form.employee_status_id"
                        :rules="rules.employeeStatusRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Status Keaktifan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.status"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        v-model="form.status"
                        :rules="rules.statusRules"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Tgl. Kontrak
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        type="date"
                        name="date"
                        step="1"
                        v-model="form.contract_date"
                        :rules="rules.contractDateRules"
                        clearable
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Masa Kontrak
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.contract_month"
                        :rules="rules.contractMonthRules"
                        clearable
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Selesai Kontrak
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        dense
                        outlined
                        type="date"
                        name="date"
                        step="1"
                        v-model="form.expire_contract_date"
                        :rules="rules.contractExpRules"
                        clearable
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Golongan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.employee_class"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                        v-model="form.gol"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. BPJS Kesehatan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field dense outlined v-model="form.bpjs_no" />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. BPJS Ketenagakerjaan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field dense outlined v-model="form.bpjs_tk_no" />
                    </v-col>
                  </div>
                </v-col>

                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Hari Libur
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        :items="dropdown.daysDropdown"
                        v-model="form.weekend_day"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        clearable
                        small-chips
                        deletable-chips
                        multiple
                        cache-items
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Finger Print ID
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        style="height: 30px; position: relative"
                        dense
                        outlined
                        v-model="form.fingerprint_id"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0">
                  <div
                    flat
                    class="d-flex justify-start align-center"
                    style="margin-left: 15px; margin-top: 5px"
                  >
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Shift
                    </p>
                    <v-switch
                      v-model="form.is_shift"
                      inset
                      color="success"
                      style="margin-left: 15px"
                    ></v-switch>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0"></v-col>
              </v-row>
            </v-card>

            <div class="d-flex justify-content-between align-center">
              <div>
                <v-btn
                  type="button"
                  rounded
                  outlined
                  elevation="0"
                  color="red"
                  @click="e6 = 1"
                  class="white--text font-weight-bold me-5"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Sebelumnya
                </v-btn>
                <v-btn
                  type="submit"
                  rounded
                  outlined
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold me-5"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Berikutnya
                </v-btn>
              </div>
              <v-btn
                type="button"
                rounded
                outlined
                elevation="0"
                color="red"
                @click="clearForm(2)"
                class="white--text font-weight-bold"
                style="font-size: 12px"
                :loading="loading"
              >
                Hapus
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">
          Selesai
          <small style="margin-top: 5px">(password dapat dilewatkan)</small>
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-form
            ref="stepThreeForm"
            @submit.prevent="nextStep(3)"
            lazy-validation
            :disabled="loading"
          >
            <v-card elevation="0" class="mb-12">
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" md="3" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col
                      cols="12"
                      style="
                        padding: 0;
                        justify-content: flex-start;
                        align-items: center;
                        display: flex;
                      "
                    >
                      <p
                        style="
                          margin: 0 10px 0 0;
                          font-size: 14px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Kelola Password
                      </p>
                      <v-checkbox
                        v-model="form.setPassword"
                        style="margin: 0; height: 25px; padding: 0"
                      ></v-checkbox>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0"><br /></v-col>
                <v-row cols="12" md="6" style="padding: 0 10px" no-gutters>
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Set Password
                    </p>
                  </v-col>
                  <v-col cols="6" style="padding: 0">
                    <v-text-field
                      v-model="form.password"
                      dense
                      outlined
                      :type="!pass ? 'password' : 'text'"
                      :disabled="!form.setPassword"
                      append-icon="mdi-eye"
                    >
                      <v-icon
                        slot="append"
                        style="cursor: pointer"
                        @click="showPass"
                      >
                        {{ pass ? 'mdi-eye' : 'mdi-eye-off' }}
                      </v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col v-if="form.setPassword" cols="6" style="padding: 15px">
                    <a
                      href=""
                      @click.prevent="generateNewPassword"
                      color="green darken-1"
                      outlined
                      style="font-size: 10px; margin: bottom"
                    >
                      Generate password
                    </a>
                  </v-col>
                </v-row>
                <!-- <v-col cols="12" style="padding: 0"></v-col>
                <v-row cols="12" md="6" style="padding: 0 10px" no-gutters>
                  <v-col cols="12" style="padding: 0;">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Ulangi Password
                    </p>
                  </v-col>
                  <v-col cols="6" style="padding: 0;">
                    <v-text-field
                      dense
                      outlined
                      :disabled="!form.setPassword"
                      append-icon="mdi-eye"
                      type="password"
                    />
                  </v-col>
                </v-row> -->
              </v-row>
            </v-card>
            <div class="d-flex justify-content-between align-center">
              <div>
                <v-btn
                  type="button"
                  rounded
                  outlined
                  elevation="0"
                  color="red"
                  @click="e6 = 2"
                  class="white--text font-weight-bold me-5"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Sebelumnya
                </v-btn>
                <v-btn
                  type="submit"
                  rounded
                  outlined
                  elevation="0"
                  color="success"
                  class="white--text font-weight-bold"
                  style="font-size: 12px"
                  :loading="loading"
                >
                  Kirim Formulir
                </v-btn>
              </div>

              <v-btn
                type="button"
                rounded
                outlined
                elevation="0"
                color="red"
                @click="clearForm(3)"
                class="white--text font-weight-bold"
                style="font-size: 12px"
                :loading="loading"
              >
                Batal
              </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
      </v-stepper>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'employeeregistrationform',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: false,
      form: {
        act: 'add',
        setPassword: false,
        nik: '',
        name: '',
        company: null,
        department_id: null,
        section_id: null,
        level_id: null,
        card_no: '',
        email: '',
        gender: null,
        religion: null,
        birth_place: '',
        birth_date: '',
        no_identitas: '',
        address: '',
        domicile_address: '',
        mobile_phone_no: '',
        education_level: '',
        mariage_status: null,
        ptkp_status_id: null,
        npwp_no: '',
        gol: null,
        employee_status_id: null,
        status: null,
        join_date: '',
        contract_date: null,
        contract_month: null,
        expire_contract_date: null,
        blood_type: '',
        bpjs_no: '',
        bpjs_tk_no: '',
        registration_type: null,
        education_major: '',
        password: '',
        is_shift: false,
        fingerprint_id: null,
        weekend_day: null
      },
      e6: 1,
      wWidth: window.innerWidth,
      rules: {
        npwpRules: [],
        birthDateRules: [],
        birthPlaceRules: [],
        religionRules: [],
        educationRules: [],
        majorRules: [],
        phoneRules: [],
        addressRules: [],
        domicileRules: [],
        nameRules: [],
        ktpRules: [],
        emailRules: [],
        genderRules: [],
        mariageRules: [],
        registrationTypeRules: [],

        nikRules: [],
        contractDateRules: [],
        contractMonthRules: [],
        contractExpRules: [],
        companyRules: [],
        departmentRules: [],
        sectionRules: [],
        positionRules: [],
        joinDateRules: [],
        statusRules: [],
        employeeStatusRules: []
      },
      dropdown: {
        department: [],
        employee_status: [],
        employee_class: [1, 2, 3, 4, 5, 6, 7, 8],
        status: [
          {
            id: 1,
            name: 'Active'
          },
          {
            id: 0,
            name: 'Inactive'
          }
        ],
        position: [],
        section: [],
        education: [
          'SD',
          'SMP',
          'SMA',
          'SMK',
          'D1',
          'D2',
          'D3',
          'D4',
          'S1',
          'S2',
          'S3'
        ],
        blood_type: ['A', 'B', 'AB', 'O', 'Rh-', 'Rh+'],
        religion: [
          {
            name: 'Islam',
            id: 1
          },
          {
            name: 'Kristen',
            id: 2
          },
          {
            name: 'Hindu',
            id: 3
          },
          {
            name: 'Budha',
            id: 4
          },
          {
            name: 'Protestan',
            id: 5
          },
          {
            name: 'Katolik',
            id: 6
          },
          {
            name: 'Konghucu',
            id: 7
          }
        ],
        ptkp_status: [],
        daysDropdown: [
          { id: 0, name: 'Minggu' },
          { id: 1, name: 'Senin' },
          { id: 2, name: 'Selasa' },
          { id: 3, name: 'Rabu' },
          { id: 4, name: 'Kamis' },
          { id: 5, name: 'Jumat' },
          { id: 6, name: 'Sabtu' }
        ]
      },
      pass: false
    }
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant'])
  },
  async mounted() {
    this.form.company = {
      id: Number(this.getUserProfile.employee.company.plant_id),
      name: this.getUserProfile.employee.company.name
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.initDropdown()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    'form.company'() {
      this.dropdownDepartment()
    },
    'form.department_id'() {
      if (this.form.department_id === null || this.form.department_id === 0) {
        this.dropdown.section = []
      } else {
        this.dropdownSection()
      }
    },
    'form.employee_status_id'() {
      if (this.form.employee_status_id !== 1) {
        this.rules.contractDateRules = []
        this.rules.contractMonthRules = []
        this.rules.contractExpRules = []
      }
    }
  },
  methods: {
    ...mapActions(['dropdownPlant']),
    initDropdown() {
      this.dropdownPlant()
      // this.dropdownDepartment()
      this.dropdownPosition()
      this.dropdownEmployeeStatus()
      this.dropdownPTKPStatus()
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    uploadFoto(event) {
      if (event !== null) {
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'photo.employee')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            console.log(res)
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.form.photo = res.data.data.name
            }
          })
          .catch((err) => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      }
    },
    clearForm(val) {
      if (val === 1) {
        // reset error validation
        // this.$refs.stepOneForm.resetValidation()

        // // reset form
        this.$refs.stepOneForm.reset()
      }
      if (val === 2) {
        // this.$refs.stepTwoForm.resetValidation()
        this.$refs.stepTwoForm.reset()
        this.form.department_id = null
      }
      if (val === 3) {
        // this.$refs.stepThreeForm.resetValidation()
        this.$refs.stepOneForm.reset()
        this.$refs.stepTwoForm.reset()
        this.$refs.stepThreeForm.reset()
        this.e6 = 1
      }
    },
    nextStep(val) {
      const self = this
      if (val === 1) {
        // this.rules.npwpRules = [v => !!v || 'NPWP is required']
        this.rules.birthDateRules = [(v) => !!v || 'Birthday is required']
        this.rules.birthPlaceRules = [(v) => !!v || 'Place is required']
        this.rules.religionRules = [(v) => !!v || 'Religion is required']
        this.rules.educationRules = [(v) => !!v || 'Education is required']
        this.rules.majorRules = [(v) => !!v || 'Major is required']
        this.rules.phoneRules = [(v) => !!v || 'Phone is required']
        this.rules.addressRules = [(v) => !!v || 'Address is required']
        this.rules.domicileRules = [(v) => !!v || 'Domicile is required']
        this.rules.nameRules = [(v) => !!v || 'Name is required']
        this.rules.ktpRules = [(v) => !!v || 'No. KTP is required']
        this.rules.emailRules = [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ]
        this.rules.genderRules = [(v) => !!v || 'Gender is required']
        this.rules.mariageRules = [
          (v) => v !== null || 'Mariage status is required'
        ]
        setTimeout(function () {
          if (self.$refs.stepOneForm.validate()) {
            self.e6 = 2
          }
        })
      }

      if (val === 2) {
        this.rules.registrationTypeRules = [
          (v) => !!v || 'Registration type is required'
        ]
        this.rules.nikRules = [(v) => !!v || 'NIK is required']
        if (this.form.employee_status_id === 1) {
          this.rules.contractDateRules = [
            (v) => !!v || 'Contract date is required'
          ]
          this.rules.contractMonthRules = [
            (v) => !!v || 'Total month is required'
          ]
          this.rules.contractExpRules = [
            (v) => !!v || 'Contract exp is required'
          ]
        }
        this.rules.companyRules = [(v) => !!v || 'Company is required']
        this.rules.departmentRules = [(v) => !!v || 'Department is required']
        this.rules.sectionRules = [(v) => !!v || 'Section is required']
        this.rules.positionRules = [(v) => !!v || 'Position is required']
        this.rules.joinDateRules = [(v) => !!v || 'Join date is required']
        this.rules.employeeStatusRules = [
          (v) => !!v || 'Employee status is required'
        ]
        setTimeout(function () {
          if (self.$refs.stepTwoForm.validate()) {
            self.e6 = 3
          }
        })
      }

      if (val === 3) {
        this.createNewForm()
      }
    },
    createNewForm() {
      const newForm = {
        act: 'add',
        setPassword: this.form.setPassword,
        nik: this.form.nik,
        name: this.form.name,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        department_id: this.form.department_id,
        level_id: this.form.level_id,
        card_no: this.form.card_no,
        email: this.form.email,
        gender: this.form.gender,
        religion: this.form.religion,
        birth_place: this.form.birth_place,
        birth_date: this.form.birth_date,
        no_identitas: this.form.no_identitas,
        address: this.form.address,
        domicile_address: this.form.domicile_address,
        mobile_phone_no: this.form.mobile_phone_no,
        education_level: this.form.education_level,
        education_major: this.form.education_major,
        mariage_status: this.form.mariage_status,
        ptkp_status_id: this.form.ptkp_status_id,
        npwp_no: this.form.npwp_no,
        gol: this.form.gol,
        employee_status_id: this.form.employee_status_id,
        status: this.form.status,
        join_date: this.form.join_date,
        contract_date: this.form.contract_date,
        contract_month: this.form.contract_month,
        expire_contract_date: this.form.expire_contract_date,
        blood_type: this.form.blood_type,
        bpjs_no: this.form.bpjs_no,
        bpjs_tk_no: this.form.bpjs_tk_no,
        photo: this.form.photo,
        registration_type: this.form.registration_type,
        password: this.form.password,
        weekend_day: this.form.weekend_day,
        fingerprint_id: this.form.fingerprint_id,
        is_shift: this.form.is_shift
      }
      this.save(newForm)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/save`, form)
        .then((res) => {
          console.log(res.data)
          this.loading = false
          if (res.data.status_code == '00') {
            this.clearForm(3)
            setTimeout(() => {
              return this.$router.push(
                `/hr/employee/detail/${res.data.created_id}`
              )
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg == 'Parameter value has problem') {
              let xFlag = false
              if (res.data.error_msg !== undefined) {
                if (res.data.error_msg !== null) {
                  if (res.data.error_msg.length > 0) {
                    this.showMsgDialog(
                      'error',
                      `${res.data.error_msg[0].msg} (${res.data.error_msg[0].value})`,
                      false
                    )
                  } else {
                    xFlag = true
                  }
                } else {
                  xFlag = true
                }
              } else {
                xFlag = true
              }

              if (xFlag) {
                this.showMsgDialog(
                  'error',
                  'Invalid input, Parameter value has problem',
                  false
                )
              }
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    close() {
      this.$refs.stepOneForm.reset()
      this.$refs.stepTwoForm.reset()
      this.$refs.stepThreeForm.reset()
      this.$router.push('/hr/employee')
    },

    async dropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.form.company.id}}]`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },

    async dropdownSection() {
      this.dropdown.section = []
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"parent_id":${this.form.department_id}}]`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.section = res.data.data)
          }
          return (this.dropdown.section = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.section = [])
        })
    },

    async dropdownEmployeeStatus() {
      await axios
        .get(`${this.hrsApi}master/universal/employeestatus/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.employee_status = res.data.data)
          }
          return (this.dropdown.employee_status = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.employee_status = [])
        })
    },

    async dropdownPosition() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    },

    async dropdownPTKPStatus() {
      await axios
        .get(`${this.hrsApi}master/universal/ptkpstatus/dropdown`)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.ptkp_status = res.data.data)
          }
          return (this.dropdown.ptkp_status = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.ptkp_status = [])
        })
    },

    generateNewPassword() {
      this.randomString(6).then((result) => {
        this.form.password = result
      })
    },

    randomString(int) {
      const length = int
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return new Promise((resolve) => {
        resolve(result)
      })
    },

    showPass() {
      if (this.pass === false) {
        this.pass = true
      } else {
        this.pass = false
      }
    }
  }
}
</script>
