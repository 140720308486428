<template>
  <v-container
    style="
      padding: 0 0 20px 0;
      margin: auto;
      border-radius: 5px;
      background: white;
    "
  >
    <v-card
      tile
      flat
      style="
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 60px;
        background: white;
        z-index: 5;
      "
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
        "
      >
        <v-toolbar-title class="overline">
          <p :style="`font-size:${wWidth > 780 ? '20px' : '3vw'}; margin: 0;`">
            Formulir Check In
          </p>
        </v-toolbar-title>
      </div>
      <div>
        <v-btn
          type="button"
          rounded
          outlined
          elevation="0"
          color="red"
          class="white--text font-weight-bold ma-2"
          style="font-size: 12px"
          @click="close"
        >
          <v-icon small>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </div>
    </v-card>

    <v-card elevation="0">
      <v-form
        ref="entryForm"
        @submit.prevent="submit()"
        lazy-validation
        :disabled="loading"
      >
        <v-row style="padding: 15px" no-gutters>
          <v-col cols="12" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kategori Customer *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-autocomplete
                  :items="[
                    { id: 1, name: 'SanQua Group Customer' },
                    { id: 2, name: 'Potential Customer' },
                    { id: 3, name: 'Other' }
                  ]"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  style="margin: 0"
                  clearable
                  v-model="form.category"
                  :rules="rules.categoryRules"
                ></v-autocomplete>
              </v-col>
            </div>
          </v-col>
          <v-col
            v-if="form.category !== null"
            cols="12"
            style="padding: 0; margin-bottom: 20px"
          >
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Lokasi
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-autocomplete
                  dense
                  item-text="name"
                  item-id="id"
                  label="Cari lokasi"
                  style="margin: 0"
                  v-model="form.customer"
                  :rules="rules.customerRules"
                  clearable
                  outlined
                  hide-details
                  hide-no-data
                  return-object
                  :items="items"
                  :search-input.sync="search"
                >
                  <template v-slot:item="{ item }">
                    <div>{{ item.name }} [{{ item.code }}]</div>
                  </template>
                </v-autocomplete>
              </v-col>
            </div>

            <div style="padding: 10px 10px; font-size: 12px">
              <a href="/" @click.prevent="addCustomer">+ tambah customer</a>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Bertemu dengan
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  dense
                  outlined
                  v-model="form.meet_with"
                  :rules="rules.meetWithRules"
                />
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Deskripsi / Catatan
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-textarea
                  dense
                  auto-grow
                  outlined
                  v-model="form.description"
                  :rules="rules.descriptionRules"
                />
              </v-col>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="d-flex justify-center align-center"
            style="padding: 0 10px; margin-bottom: 20px"
          >
            <div style="padding: 10px; border: 2px dashed grey; height: 70px">
              <v-file-input
                style="width: 170px; height: 45px"
                label="Upload Foto"
                dense
                prepend-icon=""
                append-icon="mdi-attachment"
                ref="photoUpload"
                @change="uploadFoto"
              >
                <!-- capture="camera"
                accept="image/*" -->
              </v-file-input>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-wrap"
            style="padding: 0 10px; margin-bottom: 20px"
          >
            <div
              v-for="(item, index) in form.photo"
              :key="index"
              style="
                padding: 0;
                height: 100px;
                width: 100px;
                background-color: black;
                margin: 5px;
                position: relative;
                border: 2px solid black;
                border-radius: 5px;
              "
            >
              <v-img
                :src="`${
                  build === 'development'
                    ? 'https://dev-esanqua.sanquawater.co.id'
                    : 'https://e-sanqua.sanquawater.co.id'
                }/image/ecatalogue/hris/sales_activities/${item.photo}`"
                :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`"
                style="
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                  cursor: pointer;
                "
                class="grey lighten-2"
                @click="openImage(item)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="d-flex justify-center align-center"
                style="
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  height: 30px;
                  width: 30px;
                  background-color: red;
                  border: 1px solid black;
                  border-radius: 15px 0 0 0;
                "
              >
                <v-btn
                  @click="
                    unlinkPhoto(
                      item.photo.slice(item.photo.lastIndexOf('/') + 1)
                    )
                  "
                  rounded
                  small
                  elevation="0"
                  icon
                >
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="d-flex justify-center">
          <v-btn
            rounded
            outlined
            elevation="0"
            color="green"
            type="submit"
            class="white--text font-weight-bold me-5"
            style="font-size: 12px; width: 150px"
            :loading="loading"
          >
            Simpan
          </v-btn>
          <v-btn
            type="button"
            rounded
            outlined
            elevation="0"
            color="red"
            @click="clearFormAndUnlink()"
            class="white--text font-weight-bold"
            style="font-size: 12px; width: 150px"
            :loading="loading"
          >
            Hapus
          </v-btn>
        </div>
      </v-form>
    </v-card>
    <v-dialog v-model="imageDialog" persistent>
      <div style="position: relative">
        <v-img
          :src="
            imageLink !== null
              ? `${
                  build === 'development'
                    ? 'https://dev-esanqua.sanquawater.co.id'
                    : 'https://e-sanqua.sanquawater.co.id'
                }/image/ecatalogue/hris/sales_activities/${imageLink}`
              : 'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
          "
          @error="imgError"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <v-btn
          style="
            position: absolute;
            z-index: 1;
            top: 10px;
            right: 10px;
            font-weight: bolder;
          "
          fab
          x-small
          color="black"
          @click="imageDialog = false"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog v-model="addCustomerDialog" width="500" persistent>
      <v-form
        :disabled="loading"
        ref="entryForm"
        @submit.prevent="submitAddCustomer"
        style="position: relative"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Tambah Customer
          </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <div style="margin-bottom: 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Company
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      dense
                      v-model="formX.company"
                      :items="getDropdownPlant"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      clearable
                      style="margin: 0; height: 40px"
                      :disabled="getUserProfile.employee.company.plant_id != 6"
                    ></v-autocomplete>
                  </v-col>
                </div>
                <div>
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama Customer
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0; margin-bottom: 10px">
                    <v-text-field
                      dense
                      v-model="formX.name"
                      outlined
                      :rules="rules.nameXRules"
                      style="height: 40px"
                    />
                  </v-col>
                </div>
                <div>
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Code
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0; margin-bottom: 10px">
                    <v-text-field
                      dense
                      v-model="formX.code"
                      outlined
                      style="height: 40px"
                    />
                  </v-col>
                </div>
                <div>
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Email
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0; margin-bottom: 10px">
                    <v-text-field
                      dense
                      v-model="formX.email"
                      outlined
                      style="height: 40px"
                    />
                  </v-col>
                </div>
                <div>
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Handphone
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0; margin-bottom: 10px">
                    <v-text-field
                      dense
                      v-model="formX.mobile"
                      outlined
                      style="height: 40px"
                    />
                  </v-col>
                </div>

                <div>
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Telephone
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0; margin-bottom: 10px">
                    <v-text-field
                      dense
                      v-model="formX.phone"
                      outlined
                      style="height: 40px"
                    />
                  </v-col>
                </div>
                <div>
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Alamat
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0; margin-bottom: 10px">
                    <v-text-field
                      dense
                      v-model="formX.street"
                      outlined
                      style="height: 40px"
                    />
                  </v-col>
                </div>

                <div style="margin-bottom: 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Region
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      dense
                      v-model="formX.region"
                      :items="dropdown.region"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      clearable
                      style="margin: 0; height: 40px"
                    ></v-autocomplete>
                  </v-col>
                </div>
                <div style="margin-bottom: 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Region Group
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      dense
                      v-model="formX.region_group"
                      :items="dropdown.regionGroup"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      clearable
                      style="margin: 0; height: 40px"
                    ></v-autocomplete>
                  </v-col>
                </div>

                <div style="margin-bottom: 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Region Master
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      dense
                      v-model="formX.region_master"
                      :items="dropdown.regionMaster"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      clearable
                      style="margin: 0; height: 40px"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </div>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              @click="closeCustomerDialog"
              :loading="loading"
            >
              Batal
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'activityform',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      odoo: buildType.apiURL('odoo'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: false,
      addCustomerDialog: false,
      form: {
        customer: null,
        category: null,
        description: null,
        meet_with: null,
        lt_loc: null,
        lg_loc: null,
        check_in_at: null,
        photo: []
      },
      formX: {
        act: 'add',
        name: '',
        code: '',
        street: '',
        mobile: '',
        phone: '',
        email: '',
        region_group: null,
        region_master: null,
        region: null,
        company: null
      },
      wWidth: window.innerWidth,
      rules: {
        meetWithRules: [],
        descriptionRules: [],
        customerRules: [],
        categoryRules: [],
        nameXRules: []
      },
      dropdown: {
        customer: [],
        region: [],
        regionGroup: [],
        regionMaster: [],
        company: []
      },
      items: [],
      search: null,
      select: null,
      imageLink: null,
      imageDialog: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant'])
  },
  async mounted() {
    this.dropdownPlant()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.$refs.entryForm.reset()
  },
  watch: {
    search(val) {
      if (val !== '') {
        val && val !== this.select && this.querySelections(val)
      } else {
        this.select = null
        this.items = []
      }
    },
    'form.category'() {
      if (this.form.category !== null) {
        this.querySelections('')
      } else {
        this.select = null
        this.items = []
      }
    }
    // 'form.customer'() {
    //   console.log(this.form.customer)
    // }
  },
  methods: {
    ...mapActions(['dropdownPlant']),
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    addCustomer() {
      setTimeout(() => {
        this.addCustomerDialog = true

        this.formX.company = {
          id: Number(this.getUserProfile.employee.company.plant_id),
          name: this.getUserProfile.employee.company.name
        }
        this.dropdownRegion()
        this.dropdownRegionGroup()
        this.dropdownRegionMaster()
      }, 200)
    },
    submitAddCustomer() {
      this.rules.nameXRules = [(v) => !!v || 'Name is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewCustomerForm()
        }
      })
    },
    createNewCustomerForm() {
      const form = {
        act: this.formX.act,
        name: this.formX.name.toUpperCase(),
        code: this.formX.code.toUpperCase(),
        street: this.formX.street,
        mobile: this.formX.mobile,
        phone: this.formX.phone,
        email: this.formX.email,
        region_group_id:
          this.formX.region_group !== null ? this.formX.region_group.id : null,
        region_group_name:
          this.formX.region_group !== null
            ? this.formX.region_group.name
            : null,
        region_master_id:
          this.formX.region_master !== null
            ? this.formX.region_master.id
            : null,
        region_master_name:
          this.formX.region_master !== null
            ? this.formX.region_master.name
            : null,
        region_id: this.formX.region !== null ? this.formX.region.id : null,
        region_name: this.formX.region !== null ? this.formX.region.name : null,
        company_id: this.formX.company.id,
        company_name: this.formX.company.name,
        type: this.form.category
      }
      console.log(form)
      this.saveAddCustomer(form)
    },
    async saveAddCustomer(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}master/universal/salesmanothercustomer/save`, form)
        .then(async (res) => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.querySelections('')
            this.closeCustomerDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    closeCustomerDialog() {
      setTimeout(() => {
        this.dropdown.company = []
        this.dropdown.region = []
        this.dropdown.regionGroup = []
        this.dropdown.regionMaste = []

        this.rules.nameXRules = []
        this.formX = {
          act: 'add',
          name: '',
          code: '',
          street: '',
          mobile: '',
          phone: '',
          email: '',
          region_group: null,
          region_master: null,
          region: null,
          company: null
        }
        this.addCustomerDialog = false
      }, 200)
    },
    clearFormAndUnlink() {
      // // reset form
      this.unlinkAllPhoto()
      setTimeout(() => {
        this.$refs.entryForm.reset()
        this.$refs.photoUpload.reset()
        this.rules.meetWithRules = []
        this.rules.descriptionRules = []
        this.rules.customerRules = []
        this.rules.categoryRules = []
        this.form = {
          customer: null,
          description: null,
          meet_with: null,
          lt_loc: null,
          lg_loc: null,
          check_in_at: null,
          photo: [],
          category: null
        }
      }, 300)
    },
    clearForm() {
      // // reset form
      setTimeout(() => {
        this.$refs.entryForm.reset()
        this.$refs.photoUpload.reset()
        this.rules.meetWithRules = []
        this.rules.descriptionRules = []
        this.rules.customerRules = []
        this.rules.categoryRules = []
        this.form = {
          customer: null,
          description: null,
          meet_with: null,
          lt_loc: null,
          lg_loc: null,
          check_in_at: null,
          photo: [],
          category: null
        }
      }, 300)
    },
    submit() {
      const self = this
      this.rules.categoryRules = [(v) => !!v || 'Category is required']
      this.rules.customerRules = [(v) => !!v || 'Lokasi is required']
      this.rules.descriptionRules = [(v) => !!v || 'Description is required']
      this.rules.meetWithRules = [(v) => !!v || 'Meet with is required']

      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.getDateTime()
        }
      })
    },
    getDateTime() {
      this.loading = true
      console.log('hereee 1')

      const d = new Date()
      const date =
        d.getDate().toString().length === 1 ? `0${d.getDate()}` : d.getDate()
      let month = d.getMonth() + 1
      if (month.toString().length === 1) {
        month = `0${month}`
      }
      const year = d.getFullYear()
      const hours = d.getHours()
      const minutes = d.getMinutes()
      const seconds = d.getSeconds()
      this.form.check_in_at = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`

      this.getLocation()
    },
    async getLocation() {
      if (navigator.geolocation) {
        console.log('hereee 2')
        this.isLocationSupported = true
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.errorPosition
        )
        console.log('hereee 2.2')
      } else {
        console.log('hereee 3')
        this.isLocationSupported = false

        this.loading = false
        this.showMsgDialog(
          'warning',
          'Aktifkan dahulu akses lokasi anda !',
          false
        )
      }
      // navigator.permissions.query({ name: 'geolocation' }).then(res => {
      //   if (res.state === 'granted') {
      //     this.isLocationSupported = true
      //     navigator.geolocation.getCurrentPosition(this.showPosition)
      //   } else {
      //     this.isLocationSupported = false
      //     this.loading = false
      //     this.showMsgDialog(
      //       'warning',
      //       'Aktifkan dahulu akses lokasi anda !',
      //       false
      //     )
      //   }
      // })
      // if (navigator.geolocation) {
      //   this.isLocationSupported = true
      //   navigator.geolocation.getCurrentPosition(this.showPosition)
      // } else {
      //   this.isLocationSupported = false
      //   this.loading = false
      // }
    },
    errorPosition(err) {
      console.log('heree 2.3', err)
      this.loading = false
      this.showMsgDialog(
        'warning',
        'Akses lokasi ditolak, media tidak mendukung http',
        false
      )
    },
    showPosition(position) {
      console.log('heree 2.1')

      this.form.lt_loc = position.coords.latitude
      this.form.lg_loc = position.coords.longitude
      this.createNewForm()
    },
    createNewForm() {
      const form = {
        odoo_customer_id: this.form.customer.id,
        odoo_customer_name: this.form.customer.name,
        odoo_customer_code: this.form.customer.code,
        odoo_customer_address: this.form.customer.street,

        lt_loc: this.form.lt_loc,
        lg_loc: this.form.lg_loc,
        check_in_at: this.form.check_in_at,
        check_out_at: null,
        meet_with: this.form.meet_with,
        description: this.form.description,
        photo: this.form.photo,
        type: this.form.category
      }
      console.log('hereee 4')
      if (this.form.category !== null) {
        if (this.form.category === 1) {
          Object.assign(form, {
            odoo_region_group_id:
              this.form.customer.region_group !== null
                ? this.form.customer.region_group.id
                : null,
            odoo_region_group_name:
              this.form.customer.region_group !== null
                ? this.form.customer.region_group.name
                : null,
            odoo_area_id:
              this.form.customer.region_master !== null
                ? this.form.customer.region_master.id
                : null,
            odoo_area_name:
              this.form.customer.region_master !== null
                ? this.form.customer.region_master.name
                : null,
            odoo_region_id:
              this.form.customer.region !== null
                ? this.form.customer.region.id
                : null,
            odoo_region_name:
              this.form.customer.region !== null
                ? this.form.customer.region.name
                : null
          })
        } else {
          Object.assign(form, {
            odoo_region_group_id: this.form.customer.region_group_id,
            odoo_region_group_name: this.form.customer.region_group_name,
            odoo_area_id: this.form.customer.region_master_id,
            odoo_area_name: this.form.customer.region_master_name,
            odoo_region_id: this.form.customer.region_id,
            odoo_region_name: this.form.customer.region_name
          })
        }
      }
      this.loading = false
      console.log('hereee 5')
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}salesman_activity/check_in`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code == '00') {
            this.clearForm()
            this.showMsgDialog('success', res.data.status_msg, false).then(
              (response) => {
                setTimeout(() => {
                  this.$router.push(
                    `/hr/activity/detail/${res.data.created_id}`
                  )
                }, 500)
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
            return null
          }
        })
        .catch((err) => {
          this.showMsgDialog('error', err.message, false)
          this.loading = false
        })
    },

    uploadFoto(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'sales_activities')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            if (res.data.status === true) {
              this.form.photo.push({
                photo: `${res.data.data.name}`
              })
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.loading = false
            this.$refs.photoUpload.reset()
          })
          .catch((err) => {
            this.loading = false
            this.$refs.photoUpload.reset()
            this.showMsgDialog('error', err.message, false)
          })
      }
    },
    querySelections(v) {
      let url = ''
      if (this.form.category !== null) {
        if (this.form.category === 1) {
          url = `${this.odoo}res_partner/customer/list?keyword=${v}&offset=0&limit=10`
        } else {
          url = `${this.hrsApi}master/universal/salesmanothercustomer/list?keyword=${v}&limit=all&offset=0&filter=[{"company_id":${this.getUserProfile.employee.company.plant_id},"type":${this.form.category}}]`
        }
      }
      axios
        .get(url)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.items = res.data.data)
          }
          return (this.items = [])
        })
        .catch((err) => {
          this.items = []
          return console.log(err)
        })
    },
    close() {
      this.$refs.entryForm.reset()
      if (this.prevRoute !== undefined) {
        this.$router.push(this.prevRoute.path)
      } else {
        this.$router.push('/hr/activity')
      }
    },
    openImage(img) {
      this.imageLink = null

      this.imageLink = img.photo
      setTimeout(() => {
        this.imageDialog = true
      }, 300)
    },
    imgError() {
      // this.isImgError = true
      this.imageLink =
        'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    async unlinkPhoto(photoName) {
      this.loading = true
      const form = {
        file_name: photoName,
        module: 'sales_activities'
      }
      await axios
        .post(`${this.upload}esanqua/hris/unlink`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            this.removeFormPhoto(photoName)
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
            if (res.data.status_msg === 'File not found') {
              this.removeFormPhoto(photoName)
            }
          }
          this.loading = false
          this.$refs.photoUpload.reset()
        })
        .catch((err) => {
          this.showMsgDialog('error', err.message, false)
          this.loading = false
        })
    },
    removeFormPhoto(fName) {
      for (let i = 0; i < this.form.photo.length; i++) {
        if (this.form.photo[i].photo.includes(fName)) {
          this.form.photo.splice(i, 1)
        }
      }
    },
    unlinkAllPhoto() {
      for (let i = 0; i < this.form.photo.length; i++) {
        const form = {
          file_name: this.form.photo[i].photo,
          module: 'sales_activities'
        }
        axios
          .post(`${this.upload}esanqua/hris/unlink`, form)
          .then((res) => {
            if (res.data.status_code === '-99') {
              this.showMsgDialog(
                'error',
                `${res.data.status_msg} (${i})`,
                false
              )
            }
          })
          .catch((err) => {
            this.showMsgDialog('error', `${err.message} (${i})`, false)
          })
      }
    },

    // dropdownPlant() {

    //   axios
    //     .get(
    //       `${this.odoo}master/universal/region.group/dropdown?keyword=&offset=0&limit=all`
    //     )
    //     .then(res => {
    //       if (res.data.status_code === '00') {
    //         return (this.dropdown.regionGroup = res.data.data)
    //       }
    //       return (this.dropdown.regionGroup = [])
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       return (this.dropdown.regionGroup = [])
    //     })
    // },
    dropdownRegion() {
      axios
        .get(
          `${this.odoo}master/universal/region.region/dropdown?keyword=&offset=0&limit=all`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.region = res.data.data)
          }
          return (this.dropdown.region = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.region = [])
        })
    },

    dropdownRegionGroup() {
      axios
        .get(
          `${this.odoo}master/universal/region.group/dropdown?keyword=&offset=0&limit=all`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.regionGroup = res.data.data)
          }
          return (this.dropdown.regionGroup = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.regionGroup = [])
        })
    },
    dropdownRegionMaster() {
      axios
        .get(
          `${this.odoo}master/universal/region.master/dropdown?keyword=&offset=0&limit=all`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.regionMaster = res.data.data)
          }
          return (this.dropdown.regionMaster = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.regionMaster = [])
        })
    }
  }
}
</script>
