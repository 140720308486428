<template>
  <div class="fluid" id="presensiList" v-if="permission !== null">
    <div v-if="permission.read_perm === 1">
      <div class="presensi-list-div">
        <v-card class="px-3 py-2" style="width: 100%">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h6 class="indigo--text" style="font-weight: bold; margin: auto">
                LAPORAN PRESENSI
              </h6>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="presensi-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div
                    class="d-flex justify-content-between align-center"
                    style="width: 100%"
                  >
                    <div class="d-flex justify-content-between align-center">
                      <div style="width: 20%; margin-right: 10px">
                        <v-text-field
                          class="attendance-start-date ml-1 mr-2"
                          dense
                          label="Start Date"
                          type="date"
                          name="startdate"
                          style="height: 30px"
                          step="1"
                          v-model="start_date"
                          @change="startDateWatcher"
                          :disabled="isToday"
                        />
                      </div>

                      <div style="width: 20%; margin-right: 10px">
                        <v-text-field
                          class="attendance-start-date ml-1 mr-2"
                          dense
                          label="End Date"
                          type="date"
                          name="enddate"
                          style="height: 30px"
                          step="1"
                          v-model="end_date"
                          @change="endDateWatcher"
                          :disabled="isToday"
                        />
                      </div>
                      <div style="width: 200px; margin-right: 10px">
                        <v-select
                          v-if="
                            this.getUserProfile.level.find(
                              ({ id }) =>
                                id === '38' || id === '39' || id === '42'
                            ) !== undefined
                          "
                          label="Company"
                          style="
                            height: 30px;
                            position: relative;
                            top: 3px;
                            margin-left: 10px;
                            font-size: 16px;
                          "
                          :items="dropdown.company"
                          item-text="name"
                          item-value="id"
                          return-id
                          dense
                          v-model="company_id"
                          @change="companyIdWatcher"
                          :disabled="
                            getUserProfile.employee.company.plant_id != 6
                          "
                        >
                          <!-- :disabled="
                            this.getUserProfile.level.find(
                              ({ id }) => id === '1'
                            ) !== undefined ||
                              this.getUserProfile.level.find(
                                ({ id }) => id === '37'
                              ) !== undefined
                          " -->
                        </v-select>
                      </div>
                      <div
                        v-if="
                          this.getUserProfile.level.find(
                            ({ id }) => id === '1'
                          ) !== undefined ||
                          this.getUserProfile.level.find(
                            ({ id }) => id === '37'
                          ) !== undefined
                        "
                        style="position: relative; top: -10px"
                      >
                        <div style="font-size: 12px; color: grey">Mode</div>
                        <div class="d-flex justify-between align-center">
                          <div
                            style="width: 100px; margin-right: 10px"
                            class="d-flex"
                          >
                            <p
                              class="text-left"
                              style="margin: 0; font-size: 12px"
                            >
                              Terakhir
                            </p>
                            <v-checkbox
                              v-model="isToday"
                              @change="isTodayWatcher"
                              style="
                                margin: 0 0 0 10px;
                                height: 25px;
                                padding: 0;
                              "
                            ></v-checkbox>
                          </div>
                          <div
                            style="width: 100px; margin-right: 10px"
                            class="d-flex"
                          >
                            <p
                              class="text-left"
                              style="margin: 0; font-size: 12px"
                            >
                              Bawahan
                            </p>
                            <v-checkbox
                              v-model="isManagedEmployee"
                              @change="isManagedEmployeeWatcher"
                              style="
                                margin: 0 0 0 10px;
                                height: 25px;
                                padding: 0;
                              "
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="margin-left: 10px" class="d-flex">
                      <div
                        style="
                          margin: 0 10px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <v-btn
                          rounded
                          small
                          elevation="1"
                          color="indigo"
                          class="white--text"
                          style="font-size: 12px"
                          @click.stop="importData"
                        >
                          Import
                          <v-icon size="15" style="margin-left: 5px">
                            mdi-file-import-outline
                          </v-icon>
                        </v-btn>
                      </div>
                      <v-text-field
                        v-model="paramAPI.keyword"
                        label="Cari disini"
                        type="search"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        style="
                          height: 30px;
                          position: relative;
                          font-size: 16px;
                        "
                        @keyup.enter="searchEnter"
                        :disabled="loading"
                      ></v-text-field>
                    </div>
                  </div>
                </v-toolbar>
                <v-toolbar
                  v-if="
                    this.getUserProfile.level.find(
                      ({ id }) => id === '38' || id === '39'
                    ) !== undefined
                  "
                  class="presensi-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-btn
                    outlined
                    elevation="1"
                    color="indigo"
                    class="indigo--text font-weight-bold"
                    style="font-size: 12px"
                    @click="generateAttendance"
                    :disabled="loading"
                  >
                    Regenerate
                  </v-btn>
                  <v-btn
                    outlined
                    elevation="1"
                    color="indigo"
                    class="indigo--text font-weight-bold"
                    style="font-size: 12px; margin-left: 15px"
                    @click="downloadReport"
                    :disabled="loading"
                  >
                    Report V1
                  </v-btn>

                  <v-dialog v-model="rDialog" width="500" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size: 12px; margin-left: 20px"
                        :disabled="loading"
                      >
                        Report V2
                      </v-btn>
                    </template>

                    <v-form
                      :disabled="loading"
                      ref="entryForm"
                      @submit.prevent="rSubmit"
                      style="position: relative"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Generate Complete Report
                        </v-card-title>

                        <v-card-text class="d-flex justify-center align-center">
                          <v-row style="padding: 10px 40px">
                            <v-col
                              md="12"
                              style="padding: 0; position: relative; top: 20px"
                            >
                              <div style="padding: 0 10px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Company
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <v-select
                                    v-if="
                                      this.getUserProfile.level.find(
                                        ({ id }) => id === '38'
                                      ) !== undefined ||
                                      this.getUserProfile.level.find(
                                        ({ id }) => id === '39'
                                      ) !== undefined
                                    "
                                    style="font-size: 16px"
                                    :items="dropdown.company"
                                    item-text="name"
                                    item-value="id"
                                    return-id
                                    dense
                                    v-model="rForm.company_id"
                                    @change="companyIdWatcher"
                                    outlined
                                  >
                                    <!-- :disabled="
                                      getUserProfile.employee.company
                                        .plant_id != 6
                                    " -->
                                  </v-select>
                                </v-col>
                              </div>
                            </v-col>
                            <v-col
                              md="12"
                              style="padding: 0; position: relative; top: 20px"
                            >
                              <div style="padding: 0 10px">
                                <v-col cols="12" style="padding: 0">
                                  <p
                                    class="text-left"
                                    style="
                                      margin: 0;
                                      font-size: 12px;
                                      font-weight: bold;
                                      color: rgba(0, 0, 0, 0.5);
                                    "
                                  >
                                    Month
                                  </p>
                                </v-col>
                                <v-col cols="12" style="padding: 0">
                                  <!-- <v-text-field
                                        dense
                                        type="month"
                                        v-model="rForm.month"
                                        outlined
                                        :rules="rules.monthRules"
                                      /> -->
                                  <v-row no-gutters>
                                    <v-col cols="6" style="padding: 0">
                                      <v-select
                                        style="font-size: 16px"
                                        :items="dropdown.month"
                                        label="month"
                                        item-text="name"
                                        item-value="id"
                                        return-id
                                        dense
                                        v-model="rForm.month"
                                        :rules="rules.monthRules"
                                        outlined
                                      >
                                      </v-select>
                                    </v-col>
                                    <v-col cols="1" style="padding: 0"></v-col>
                                    <v-col cols="5" style="padding: 0">
                                      <v-select
                                        style="font-size: 16px"
                                        label="year"
                                        :items="dropdown.year"
                                        dense
                                        v-model="rForm.year"
                                        :rules="rules.yearRules"
                                        outlined
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="error"
                            text
                            outlined
                            @click="closeRDialog"
                            :loading="loading"
                          >
                            Batal
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            type="submit"
                            text
                            outlined
                            :loading="loading"
                          >
                            Download Report
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>

                  <v-btn
                    outlined
                    elevation="1"
                    color="indigo"
                    class="indigo--text font-weight-bold"
                    style="font-size: 12px; margin-left: 20px"
                    @click="exportAttendanceV3"
                    :disabled="loading"
                  >
                    Report V3
                  </v-btn>
                </v-toolbar>

                <v-toolbar
                  v-if="
                    this.getUserProfile.level.find(({ id }) => id === '42') !==
                    undefined
                  "
                  class="presensi-list-toolbar-1"
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <v-btn
                    outlined
                    elevation="1"
                    color="indigo"
                    class="indigo--text font-weight-bold"
                    style="font-size: 12px"
                    @click="exportAttendanceV3"
                    :disabled="loading"
                  >
                    Report V3
                  </v-btn>
                </v-toolbar>
                <v-toolbar
                  class="presensi-list-toolbar-2"
                  flat
                  style="
                    height: 60px;
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;
                  "
                >
                  <div
                    v-if="
                      this.getUserProfile.level.find(
                        ({ id }) => id === '1' || id === '37'
                      ) !== undefined
                    "
                    class="d-flex"
                  >
                    <div style="position: relative; top: -5px">
                      <div style="font-size: 12px; color: grey">Mode</div>
                      <div class="d-flex justify-between align-center">
                        <div
                          style="width: 100px; margin-right: 10px"
                          class="d-flex"
                        >
                          <p
                            class="text-left"
                            style="margin: 0; font-size: 12px"
                          >
                            Hari ini
                          </p>
                          <v-checkbox
                            v-model="isToday"
                            @change="isTodayWatcher"
                            style="margin: 0 0 0 10px; height: 25px; padding: 0"
                          ></v-checkbox>
                        </div>
                        <div
                          style="width: 100px; margin-right: 10px"
                          class="d-flex"
                        >
                          <p
                            class="text-left"
                            style="margin: 0; font-size: 12px"
                          >
                            Bawahan
                          </p>
                          <v-checkbox
                            v-model="isManagedEmployee"
                            @change="isManagedEmployeeWatcher"
                            style="margin: 0 0 0 10px; height: 25px; padding: 0"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <v-select
                    v-else
                    label="Company"
                    style="height: 30px; position: relative"
                    :items="dropdown.company"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    :disabled="
                      this.getUserProfile.employee.company.plant_id != 6
                    "
                    v-model="company_id"
                    @change="companyIdWatcher"
                  ></v-select>
                </v-toolbar>
                <v-toolbar
                  class="presensi-list-toolbar-2"
                  flat
                  style="
                    height: 60px;
                    border-radius: 10px 10px 0 0;
                    overflow: auto;
                  "
                >
                  <div style="width: 140px; margin-right: 10px">
                    <v-text-field
                      class="attendance-start-date ml-1 mr-2"
                      dense
                      label="Start Date"
                      type="date"
                      name="startdate"
                      style="height: 30px"
                      step="1"
                      v-model="start_date"
                      @change="startDateWatcher"
                      :disabled="isToday"
                    />
                  </div>

                  <div style="width: 120px; margin-right: 10px">
                    <v-text-field
                      class="attendance-start-date ml-1 mr-2"
                      dense
                      label="End Date"
                      type="date"
                      name="enddate"
                      style="height: 30px"
                      step="1"
                      v-model="end_date"
                      @change="endDateWatcher"
                      :disabled="isToday"
                    />
                  </div>
                </v-toolbar>
                <v-toolbar
                  class="presensi-list-toolbar-2"
                  flat
                  style="height: 50px"
                >
                  <v-text-field
                    v-model="paramAPI.keyword"
                    label="Cari disini"
                    type="search"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    style="
                      height: 30px;
                      position: relative;
                      font-size: 16px;
                      top: -10px;
                    "
                    @keyup.enter="searchEnter"
                    :disabled="loading"
                  >
                  </v-text-field>
                </v-toolbar>
                <div
                  v-if="
                    this.getUserProfile.level.find(({ id }) => id === '38') !==
                      undefined ||
                    this.getUserProfile.level.find(({ id }) => id === '39') !==
                      undefined
                  "
                >
                  <v-toolbar
                    class="presensi-list-toolbar-2"
                    flat
                    style="height: 50px"
                  >
                    <v-btn
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold"
                      style="
                        font-size: 12px;
                        width: 100%;
                        position: relative;
                        top: -5px;
                      "
                      @click="generateAttendance"
                      :disabled="loading"
                    >
                      Regenerate Report
                    </v-btn>
                  </v-toolbar>
                  <v-toolbar
                    class="presensi-list-toolbar-2"
                    flat
                    style="height: 50px; border-bottom: 1px solid #e0e0e0"
                  >
                    <v-btn
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold"
                      style="
                        font-size: 12px;
                        width: 100%;
                        position: relative;
                        top: -5px;
                      "
                      @click="downloadReport"
                      :disabled="loading"
                    >
                      Download Rekapan
                    </v-btn>
                  </v-toolbar>
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="57vh"
                style="cursor: pointer"
                :headers="headers"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100, 10000]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.date`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ convertDate(item.date) }}
                  </div>
                </template>
                <template v-slot:[`item.clock_in`]="{ item }">
                  <v-chip
                    :color="getColorStatus(item.status_clock_in)"
                    green
                    style="cursor: pointer"
                  >
                    <div
                      @click.stop="viewImage([1, item.picture_in])"
                      v-if="item.clock_in !== null"
                      class="d-flex justify-center align-center"
                    >
                      <span style="margin-right: 2px">{{
                        attendFrom(item.attend_from_in)
                      }}</span>
                      {{ convertDate2(item.date) }}
                      {{ item.clock_in }}
                      <div
                        v-if="item.attend_from_in !== null"
                        style="margin-left: 2px"
                      >
                        <v-btn icon small>
                          <v-icon small color="primary">mdi-image</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-chip>
                </template>
                <template v-slot:[`item.clock_out`]="{ item }">
                  <v-chip
                    :color="getColorStatus(item.status_clock_out)"
                    green
                    style="cursor: pointer"
                  >
                    <div
                      @click.stop="viewImage([2, item.picture_out])"
                      v-if="item.clock_out !== null"
                      class="d-flex justify-center align-center"
                    >
                      <span style="margin-right: 2px">{{
                        attendFrom(item.attend_from_out)
                      }}</span>

                      {{ convertDate2(item.clock_out_date) }}
                      {{ item.clock_out }}
                      <div
                        v-if="item.attend_from_out !== null"
                        style="margin-left: 2px"
                      >
                        <v-btn icon small>
                          <v-icon small color="primary">mdi-image</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-chip>
                </template>
                <template v-slot:[`item.body_temperature_in`]="{ item }">
                  <v-chip
                    :color="getTemperatureStatus(item.body_temperature_in)"
                    green
                  >
                    {{ item.body_temperature_in }}
                  </v-chip>
                </template>
                <template v-slot:[`item.use_mask_in`]="{ item }">
                  <v-chip :color="getUseMaskStatus(item.use_mask_in)" green>
                    {{ item.use_mask_in }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>

            <v-dialog
              v-model="dialog"
              :width="wWidth > 780 ? '80vw' : '100vw'"
              persistent
            >
              <v-form readonly ref="entryForm" style="position: relative">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Detail Presensi
                  </v-card-title>

                  <v-card-text
                    v-if="detail !== null"
                    class="d-flex justify-center align-center"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Name
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field dense :value="detail.employee_name" />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              NIK
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field dense :value="detail.nik" />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Date
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field
                              dense
                              type="date"
                              name="date"
                              step="1"
                              :value="detail.date"
                              append-icon=""
                              readonly
                            />
                          </v-col>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Clock In
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field
                              dense
                              :value="
                                convertDate2(detail.date) +
                                ' ' +
                                detail.clock_in
                              "
                              style="font-size: 12px"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Lokasi
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field
                              v-if="detail.attend_from_in === 1"
                              :value="detail.attend_from_company_name_in"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                            <v-text-field
                              v-if="detail.attend_from_in === 2"
                              value="Lain-lain"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                            <v-text-field
                              v-if="detail.attend_from_in === 3"
                              value="Rumah"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Note
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field
                              v-if="detail.clock_in_note !== undefined"
                              :value="detail.clock_in_note"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        style="
                          padding: 0;
                          position: relative;
                          top: 10px;
                          margin-bottom: 10px;
                        "
                      >
                        <div
                          style="padding: 0 10px; margin: auto"
                          class="d-flex flex-column"
                        >
                          <a
                            href="/"
                            @click.prevent="imgDialog(1)"
                            style="font-size: 12px"
                          >
                            Lihat gambar</a
                          >
                          <a
                            :href="`https://www.google.com/maps/search/?api=1&query=${detail.clock_in_lt},${detail.clock_in_ln}`"
                            target="_blank"
                            style="font-size: 12px"
                          >
                            Buka google map</a
                          >
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Clock Out
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field
                              dense
                              :value="
                                convertDate2(detail.clock_out_date) +
                                ' ' +
                                detail.clock_out
                              "
                              style="font-size: 12px"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Lokasi
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field
                              v-if="detail.attend_from_out === 1"
                              :value="detail.attend_from_company_name_out"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                            <v-text-field
                              v-if="detail.attend_from_out === 2"
                              value="Lain-lain"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                            <v-text-field
                              v-if="detail.attend_from_out === 3"
                              value="Rumah"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                          </v-col>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div style="padding: 0 10px">
                          <v-col cols="12" style="padding: 0">
                            <p
                              class="text-left"
                              style="
                                margin: 0;
                                font-size: 12px;
                                font-weight: bold;
                                color: rgba(0, 0, 0, 0.5);
                              "
                            >
                              Note
                            </p>
                          </v-col>
                          <v-col cols="12" style="padding: 0">
                            <v-text-field
                              v-if="detail.clock_out_note !== undefined"
                              :value="detail.clock_out_note"
                              dense
                              style="height: 55px; font-size: 12px"
                            />
                          </v-col>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                        style="padding: 0; position: relative; top: 10px"
                      >
                        <div
                          style="padding: 0 10px; margin: auto"
                          class="d-flex flex-column"
                        >
                          <a
                            href="/"
                            @click.prevent="imgDialog(2)"
                            style="font-size: 12px"
                          >
                            Lihat gambar</a
                          >
                          <a
                            :href="`https://www.google.com/maps/search/?api=1&query=${detail.clock_out_lt},${detail.clock_out_ln}`"
                            target="_blank"
                            style="font-size: 12px"
                          >
                            Buka google map</a
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="error"
                      text
                      outlined
                      @click="close()"
                      :loading="loading"
                    >
                      Tutup
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-card
      v-else
      flat
      height="90vh"
      style="display: flex; justify-content: center; align-items: center"
    >
      <v-toolbar-title class="overline">
        You Can't Access This Page</v-toolbar-title
      >
    </v-card>

    <v-dialog v-model="openImageDialog" persistent>
      <div style="position: relative">
        <v-img v-if="openImageDialog" :src="imgSrc" @error="imgError">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-btn
          color="black"
          fab
          x-small
          @click="openImageDialog = !openImageDialog"
          style="position: absolute; top: 10px; right: 10px"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-dialog>
    <download-csv
      v-if="recapitulationAttendances"
      style="font-size: 0; width: 0; height: 0; overflow: hidden"
      id="exportRecap"
      :data="recapitulationAttendances"
      worksheet="My Worksheet"
      name="ReportRecapitulationAttendance.csv"
    >
    </download-csv>
    <EmployeeImport @loadPage="initDataTable" />
  </div>
  <div v-else></div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'presencereport',
  components: {
    EmployeeImport: () => import('./components/attendanceimport.vue')
  },
  data: () => ({
    dialog: false,
    rDialog: false,
    wWidth: window.innerWidth,
    // hrsApi: buildType.apiURL('hrsV2'),
    attendanceApi: buildType.apiURL('attendanceTrial'),
    mode: 'last',
    isToday: false,
    isManagedEmployee: false,
    company_id: null,
    start_date: '',
    end_date: '',
    headers: [
      {
        text: 'NIK',
        value: 'nik',
        align: 'left',
        sortable: false
      },
      {
        text: 'Name',
        value: 'employee_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Date',
        value: 'date',
        align: 'left',
        sortable: false
        // width: 150
      },
      {
        text: 'Clock In',
        value: 'clock_in',
        align: 'left',
        sortable: false
      },
      {
        text: 'Clock Out',
        value: 'clock_out',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    detail: null,
    openImageDialog: false,
    imgSrc: '',
    dropdown: {
      company: [],
      month: [],
      year: [
        2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030
      ]
    },
    totalData: 0,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortDesc: []
    },
    paramAPI: {
      page: 1,
      itemsPerPage: 10,
      keyword: '',
      limit: 10,
      offset: 0
    },
    recapitulationAttendances: [],
    rForm: {
      year: null,
      month: null,
      company_id: null
    },
    rules: {
      yearRules: [],
      monthRules: []
    },
    menu_key: 52,
    application: {
      id: 21,
      name: 'HR System'
    },
    permission: null
  }),

  created() {
    // const d = new Date()
    // d.setDate(d.getDate())
    // const n = d
    //   .toLocaleDateString('id')
    //   .split('/')
    //   .reverse()
    // if (n[1].length === 1 || n[2].length === 1) {
    //   if (n[1].length === 1) {
    //     n[1] = `0${n[1]}`
    //   }
    //   if (n[2].length === 1) {
    //     n[2] = `0${n[2]}`
    //   }
    // }
    // const joinedDate = n.join('-')
    // this.start_date = joinedDate
    // this.end_date = joinedDate
    // this.initDropdown()
    // this.initDataTable()
  },
  watch: {
    // start_date() {
    //   this.initDataTable()
    // },
    // end_date() {
    //   this.initDataTable()
    // },
    // isToday() {
    //   if (this.isToday) {
    //     this.isManagedEmployee = false
    //   }
    //   this.initDataTable()
    // },
    // isManagedEmployee() {
    //   if (this.isManagedEmployee) {
    //     this.isToday = false
    //   }
    //   this.initDataTable()
    // },
    // company_id() {
    //   this.initDataTable()
    // }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.company_id = Number(this.getUserProfile.employee.company.id)
    this.rForm.company_id = Number(this.getUserProfile.employee.company.id)
    if (
      this.getUserProfile.level.find(
        ({ application }) => application.id === 1
      ) !== undefined
    ) {
      var level_id = this.getUserProfile.level.find(
        ({ application }) => application.id === 1
      ).id
      this.checkMenuAccess([this.menu_key, level_id])
    } else if (
      this.getUserProfile.level.find(
        ({ application }) => application.id === this.application.id
      ) !== undefined
    ) {
      var levelId = this.getUserProfile.level.find(
        ({ application }) => application.id === this.application.id
      ).id
      this.checkMenuAccess([this.menu_key, levelId])
    } else {
      this.resetPermission(null)
    }

    // setTimeout(() => {
    //   this.initDataTable()
    // }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    /* 'getDropdownPlant' */
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    /* 'dropdownPlant' */
    ...mapActions(['menu_access']),
    exportAttendanceV3() {
      this.loading = true
      // const arrData = this.result
      // this.exportNow(arrData)
      this.groupingData()
    },
    async groupingData() {
      const arrAttendance = this.result

      const arrOfNik = arrAttendance.map(({ nik }) => nik)
      // console.log('arrOfNik >>>>', arrOfNik)

      const filteredNik = arrAttendance.filter(
        ({ nik }, index) => !arrOfNik.includes(nik, index + 1)
      )
      // console.log('filteredNik>>>>', filteredNik)

      const arrEmployee = []
      for (let i = 0; i < filteredNik.length; i++) {
        arrEmployee.push({
          employee_id: filteredNik[i].employee_id,
          nik: filteredNik[i].nik,
          employee_name: filteredNik[i].employee_name,
          company_name: filteredNik[i].company_name
        })
      }

      // console.log('filtered employee >>>>', arrEmployee)
      if (arrEmployee.length > 0) {
        const date = new Date(this.start_date)
        const year = date.getFullYear()
        const month = date.getMonth() + 1

        this.getAllDatesInMonthUTC(`${year}-${month}`, arrEmployee)
      } else {
        alert('No data available')
      }
    },
    async getAllDatesInMonthUTC(xYear, arrEmployee) {
      const year = xYear.slice(0, xYear.lastIndexOf('-'))
      const month = Number(xYear.slice(xYear.lastIndexOf('-') + 1)) - 1
      // get all days in selected month & year
      const daysInMonth =
        new Date(Date.UTC(year, month + 1, 1)) -
        new Date(Date.UTC(year, month, 1))

      const arrLocalDateFormat = Array.from(
        { length: daysInMonth / (24 * 60 * 60 * 1000) },
        (_, index) =>
          new Date(Date.UTC(year, month, index + 1)).toLocaleDateString('id')
      )
      console.log('arrLocalDateFormat>>>>', arrLocalDateFormat)
      const newArrFormatDate = []
      for (let i = 0; i < arrLocalDateFormat.length; i++) {
        const split = arrLocalDateFormat[i].split('/')
        const newStringFormat = `${split[1]}/${split[0]}/${split[2]}`
        newArrFormatDate.push(newStringFormat)
      }
      this.arrOfDays = newArrFormatDate
      console.log('arrOfDays>>>>', this.arrOfDays)

      // create object of days with value null
      const objOfDays = {}
      for (var key of this.arrOfDays) {
        const xDate = key.split('/')
        const newDate = `${xDate[2]}-${
          xDate[0].length > 1 ? xDate[0] : '0' + xDate[0]
        }-${xDate[1].length > 1 ? xDate[1] : '0' + xDate[1]}`

        objOfDays[newDate] = null
      }
      console.log('new object>>>>', objOfDays)
      this.setObjOfDate(arrEmployee, objOfDays)
    },
    setObjOfDate(arrEmployee, objOfDays) {
      for (let i = 0; i < arrEmployee.length; i++) {
        Object.assign(arrEmployee[i], objOfDays)
      }

      console.log('final object >>>>', arrEmployee)

      this.finalData(arrEmployee)
    },
    async finalData(arrEmployee) {
      const arrOfEmployee = arrEmployee

      const listAttendance = this.result

      for (let i = 0; i < listAttendance.length; i++) {
        if (listAttendance[i].employee_id !== null) {
          const eIndex = arrOfEmployee.findIndex(
            ({ employee_id, nik, employee_name }) =>
              employee_id === listAttendance[i].employee_id ||
              nik === listAttendance[i].nik ||
              employee_name === listAttendance[i].employee_name
          )

          // if (arrOfEmployee[eIndex][listAttendance[i].date] !== undefined) {
          //   if (arrOfEmployee[eIndex][listAttendance[i].date] === null) {
          arrOfEmployee[eIndex][listAttendance[i].date] =
            listAttendance[i].clock_in + ' - ' + listAttendance[i].clock_out
          //  {
          //   clock_in: listAttendance[i].clock_in,
          //   clock_out: listAttendance[i].clock_out
          // }
          //   }
          // }
        }
      }

      console.log('result >>>>', arrOfEmployee)
      this.exportNow(arrOfEmployee)
    },
    exportNow(selectedData) {
      const arrExport = selectedData
      console.log('start export >>>>', arrExport)
      // const arrData = []
      for (let i = 0; i < arrExport.length; i++) {
        // const param = {
        //   NIK: selectedData[i].nik,
        //   Name: selectedData[i].employee_name,
        //   Date: selectedData[i].date,
        //   Clock_in: selectedData[i].clock_in,
        //   Date_out: selectedData[i].clock_out_date,
        //   Clock_out: selectedData[i].clock_out
        // }
        // arrData.push(param)
        delete arrExport[i].employee_id
        delete arrExport[i].company_name
      }
      console.log('start export >>>>', arrExport)
      this.downloadExcell(arrExport)
    },
    async downloadExcell(arrData) {
      const data = await XLSX.utils.json_to_sheet(arrData)
      const wb = await XLSX.utils.book_new()
      await XLSX.utils.book_append_sheet(wb, data, 'data')
      await XLSX.writeFile(wb, 'rekapan_presensi.xlsx')
      setTimeout(() => {
        this.loading = false
      }, 3000)
    },

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          this.resetPermission(result)
        })
        .catch((error) => {
          console.log(error)
          if (error) {
            this.resetPermission(null)
          }
        })
    },
    resetPermission(val) {
      if (val !== null) {
        if (val.data !== null) {
          this.permission = val.data
          if (val.data.read_perm === 1) {
            this.setOptionDate()
            var level = null
            if (
              this.getUserProfile.level.find(({ id }) => id === '38') !==
              undefined
            ) {
              level = this.getUserProfile.level.find(({ id }) => id === '38')
            }
            if (
              this.getUserProfile.level.find(({ id }) => id === '39') !==
              undefined
            ) {
              level = this.getUserProfile.level.find(({ id }) => id === '39')
            }
            try {
              if (level !== null) {
                var allowed =
                  level.user_user_level.allowed_company.find(
                    ({ id }) =>
                      id ===
                      Number(this.getUserProfile.employee.company.plant_id)
                  ) !== undefined
                if (allowed) {
                  this.company_id = Number(
                    this.getUserProfile.employee.company.plant_id
                  )
                } else {
                  this.company_id = 0
                }
              } else {
                this.company_id = Number(
                  this.getUserProfile.employee.company.plant_id
                )
              }
            } catch (error) {
              this.company_id = Number(
                this.getUserProfile.employee.company.plant_id
              )
            }

            setTimeout(() => {
              this.initDataTable()
              this.initDropdown()
            }, 200)
          }
          return null
        }
      }
      this.permission = {
        create_perm: 0,
        delete_perm: 0,
        read_perm: 0,
        update_perm: 0
      }
      return null
    },
    setOptionDate() {
      console.log('setOptionDate')
      const d = new Date()
      d.setDate(d.getDate())
      const n = d.toLocaleDateString('id').split('/').reverse()
      if (n[1].length === 1 || n[2].length === 1) {
        if (n[1].length === 1) {
          n[1] = `0${n[1]}`
        }
        if (n[2].length === 1) {
          n[2] = `0${n[2]}`
        }
      }
      const joinedDate = n.join('-')
      this.start_date = joinedDate
      this.end_date = joinedDate
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      this.initDataTable()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      this.initDataTable()
    },
    initDropdown() {
      this.dropdownPlant()
      this.getDropdownMonth()
    },
    imgError() {
      // this.isImgError = true
      this.imgSrc =
        'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    imgDialog(val) {
      if (val === 1) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_in}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_in}`
        }
      }
      if (val === 2) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_out}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_out}`
        }
      }
      setTimeout(() => {
        this.openImageDialog = true
      }, 300)
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    searchEnter() {
      this.paramAPI.page = 1
      this.paramAPI.offset = 0
      this.initDataTable()
    },
    initDataTable() {
      this.result = []
      let url = `${this.attendanceApi}report/attendance/detail?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=date&order_type=ASC`

      if (
        this.getUserProfile.level.find(({ id }) => id === '1') !== undefined ||
        this.getUserProfile.level.find(({ id }) => id === '38') !== undefined ||
        this.getUserProfile.level.find(({ id }) => id === '39') !== undefined
      ) {
        url = url + `&company_id=${this.company_id}`
      } else {
        url =
          url + `&company_id=${this.getUserProfile.employee.company.plant_id}`
      }

      if (this.isToday && !this.isManagedEmployee) {
        url = url + '&mode=last'
      } else if (!this.isToday && this.isManagedEmployee) {
        url = url + '&mode=managed_employee'
      } else {
        url = url + '&mode='
      }

      if (!this.isToday) {
        if (this.start_date !== null) {
          url = url + `&start_date=${this.start_date}`
        }
        if (this.end_date !== null) {
          url = url + `&end_date=${this.end_date}`
        }
      } else {
        url = url + '&start_date=&end_date='
      }

      axios
        .get(url)
        .then((res) => {
          this.result = res.data.data
          this.totalData = Number(res.data.total_record)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    generateAttendance() {
      this.showMsgDialog(
        'question',
        `Regenerate data tanggal<br> ${this.start_date} s/d ${this.end_date} <br> Lanjutkan ?`,
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          const form = {
            start_date: this.start_date,
            end_date: this.end_date,
            company_id: this.company_id
          }
          await axios({
            method: 'post',
            url: `${this.attendanceApi}report/attendance/regenerate_report_attendance`,
            timeout: 300000,
            data: form
          })
            .then(async (res) => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.initDataTable()
              this.loading = false
            })
            .catch((err) => {
              this.showMsgDialog('error', err, 'false')
              this.loading = false
            })
        }
      })
    },
    downloadReport() {
      this.showMsgDialog(
        'question',
        `Download data tanggal<br> ${this.start_date} s/d ${this.end_date} <br> Lanjutkan ?`,
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .get(
              `${this.attendanceApi}report/attendance/generateToExcel?start_date=${this.start_date}&end_date=${this.end_date}&company_id=${this.company_id}`
            )
            .then((res) => {
              this.recapitulationAttendances = []
              if (res.data.status_code === '00') {
                this.recapitulationAttendances = res.data.formed_data

                setTimeout(() => {
                  document.getElementById('exportRecap').click()
                }, 500)
              }
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
              console.log(err)
            })
        }
      })
    },
    rSubmit() {
      this.showMsgDialog('question', 'Download Report Now ?', true).then(
        async (res) => {
          if (res.isConfirmed) {
            this.loading = true
            // const year = this.rForm.month.split('-')[0]
            // const month = this.rForm.month.split('-')[1]
            const year = this.rForm.year
            const month = this.rForm.month
            axios({
              url: `${this.attendanceApi}report/generateHCGSToExcel?month=${month}&year=${year}&company_id=${this.rForm.company_id}`,
              method: 'GET',
              responseType: 'blob'
            })
              .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                  'download',
                  `Presensi_Report_${month}/${year}/.xlsx`
                )
                document.body.appendChild(link)
                link.click()
                this.loading = false
              })
              .catch((err) => {
                this.loading = false
                console.log(err)
              })
          }
        }
      )
    },
    rowClick(pItem) {
      this.detail = pItem
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    close() {
      this.detail = null
      setTimeout(() => {
        this.dialog = false
      }, 100)
    },
    closeRDialog() {
      this.rForm.month = null
      this.rForm.company_id = null
      setTimeout(() => {
        this.rDialog = false
      }, 100)
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        // return (day + '-' + month + '-' + year).replace(/-/g, '/')
        return day + '-' + month + '-' + year
      }
    },
    convertDate2(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = Intl.DateTimeFormat('id', {
          day: 'numeric',
          month: 'long'
        }).format(date)
        return local
      }
    },

    getColorStatus(pStatus) {
      if (pStatus === 'on_time' || pStatus === 'go_after_time') {
        return ''
      } else if (pStatus === 'late' || pStatus === 'go_before_time') {
        return 'red'
      }
    },

    getTemperatureStatus(pTemperature) {
      // if (pType === 'in') {
      if (pTemperature > 37.3) {
        return 'red'
      } else {
        return ''
      }
      // }
    },

    getUseMaskStatus(pMask) {
      // if (pType === 'in') {
      if (pMask === 'YES') {
        return ''
      } else if (pMask === 'NO') {
        return 'red'
      }
      // }
    },
    dropdownPlant() {
      try {
        const app = this.getUserProfile.level.find(
          ({ application }) => application.id === 21
        )
        if (app.user_user_level.allowed_company !== null) {
          this.dropdown.company = app.user_user_level.allowed_company
        } else {
          this.dropdown.company = []
        }
      } catch (error) {
        console.log(error)
      }
    },

    startDateWatcher() {
      this.initDataTable()
    },
    endDateWatcher() {
      this.initDataTable()
    },
    isTodayWatcher() {
      if (this.isToday) {
        this.isManagedEmployee = false
      }
      this.initDataTable()
    },
    isManagedEmployeeWatcher() {
      if (this.isManagedEmployee) {
        this.isToday = false
      }
      this.initDataTable()
    },
    companyIdWatcher() {
      this.initDataTable()
    },
    viewImage(pItem) {
      if (pItem[0] === 1) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        }
      }
      if (pItem[0] === 2) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        }
      }
      setTimeout(() => {
        this.openImageDialog = true
      }, 300)
    },
    attendFrom(val) {
      switch (val) {
        case 1:
          return 'Kantor:'
        case 2:
          return 'Lain-lain:'
        case 3:
          return 'Rumah:'
        default:
          return 'Thermal:'
      }
    },

    importData() {
      setTimeout(() => {
        document.getElementById('employeeImport').click()
      }, 200)
    },

    getDropdownMonth() {
      const arr = [
        {
          id: '01',
          name: 'January'
        },
        {
          id: '02',
          name: 'February'
        },
        {
          id: '03',
          name: 'March'
        },
        {
          id: '04',
          name: 'April'
        },
        {
          id: '05',
          name: 'May'
        },
        {
          id: '06',
          name: 'June'
        },
        {
          id: '07',
          name: 'July'
        },
        {
          id: '08',
          name: 'August'
        },
        {
          id: '09',
          name: 'September'
        },
        {
          id: '10',
          name: 'October'
        },
        {
          id: '11',
          name: 'November'
        },
        {
          id: '12',
          name: 'Desember'
        }
      ]

      this.dropdown.month = arr
    }
  }
}
</script>

<style lang="scss">
#presensiList {
  position: relative;
  .presensi-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .presensi-list-toolbar-1 {
      .presensi-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .presensi-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #presensiList {
    .presensi-list-div {
      .presensi-list-toolbar-1 {
        display: none;
      }
      .presensi-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
